<template>
    <modal ref="crearPedido" v-loading="loading" titulo="Crear Pedido" no-aceptar tamano="modal-lg" adicional="Crear" @adicional="crear_pedido">
        <ValidationObserver ref="form1" tag="div" class="row mx-0 justify-center">
            <ValidationProvider v-slot="{errors}" tag="div" class="col-10" rules="required" name="vendedor">
                <p class="ml-2 text-general f-12 pl-2">{{ $config.vendedor }}</p>
                <el-select v-model="model.tienda" class="w-100" size="small" filterable placeholder="Seleccionar" @change="set_tienda">
                    <el-option
                    v-for="item in opciones"
                    :key="item.value"
                    :label="item.nombre"
                    :value="item.value"
                    />
                </el-select>
                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
            </ValidationProvider>
            <div class="col-10 d-middle">
                <i class="icon-warehouse text-gris f-20" />
                <p class="ml-2 f-14">Cedi:</p>
                <p class="ml-2 f-14">{{ tienda.cedi }}</p>
            </div>
            <ValidationProvider v-slot="{errors}" tag="div" class="col-10 my-3" rules="required" name="dirección">
                <p class="ml-2 text-general f-12 pl-2">Dirección</p>
                <el-select v-model="direccion_seleccionada" placeholder="Seleccionar dirección" class="w-100" size="small" @change="set_direccion_lechero">
                    <el-option
                    v-for="item in direcciones"
                    :key="item.id"
                    :label="item.direccion"
                    :value="item.id"
                    />
                </el-select>
                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
            </ValidationProvider>
            <div class="col-10 mt-3">
                <div class="row f-14 my-2 mx-0 justify-center">
                    <div class="col pl-0">
                        <p class="ml-2 text-general f-12">Fecha de Entrega</p>
                        <el-date-picker
                        v-model="model.entrega_fecha"
                        type="date"
                        size="small"
                        class="w-100"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="set_franja"
                        />
                        <p v-show="errorFecha" class="text-danger w-100 f-11 pl-2">Fecha sugerida {{ fechaValida | helper-fecha('DD MMMM') }}</p>
                    </div>
                    <div class="col">
                        <p class="ml-2 text-general f-12">Hora de Entrega</p>
                        <el-select v-model="model.entrega_horario" size="small" placeholder="Horario" class="w-100">
                            <el-option
                            v-for="item in horaEntrega"
                            :key="item.hora"
                            :label="item.horario"
                            :value="item.hora"
                            :disabled="item.disable"
                            />
                        </el-select>
                    </div>
                    <div class="col pr-0">
                        <p class="ml-2 text-general f-12">Valor Domicilio</p>
                        <div class="d-middle">
                            <money v-model="model.domicilio" class="input-money w-100" v-bind="money" />
                            <span v-show="model.tienda" class="border p-1 br-r-10 bg-gris f-600" style="margin-left: -8px; width: 50px; height:32px;">{{ tienda.sigla }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-10 my-3">
                <p class="ml-2 text-general f-12">Instrucciones</p>
                <el-input
                v-model="model.instrucciones"
                type="textarea"
                rows="4"
                placeholder="Digite la instrucciones de entrega"
                maxlength="1000"
                show-word-limit
                />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {Money} from 'v-money'
import {mapGetters} from 'vuex'
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    components:{
        Money,
    },
    data(){
        let that = this;
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < that.fechaMinima;
                }
            },
            direcciones:[],
            direccion_seleccionada:null,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            loading:false,
            opciones:[],
            horarios:[],
            tienda:{},
            model:{
                tienda:'',
                domicilio:0,
                instrucciones:'',
                entrega_fecha:'',
                entrega_horario:''
            },
            errorFecha:false,
            fechaMinima: moment().subtract(1, "days"),
            fechaValida: moment(),
            horaEntrega: [],
            horario: ''
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        })
    },
    methods: {
        toggle(){
            this.model.tienda = ''
            this.model.domicilio = 0
            this.model.instrucciones = ''
            this.model.entrega_fecha = '',
            this.model.entrega_horario = ''
            this.tienda = {}

            this.select_tiendas()
            this.$refs.crearPedido.toggle()
        },
        async select_tiendas(){
            const {data} = await Pedidos.select_tiendas(this.id_cedis)
            this.opciones = data
        },
        async crear_pedido(){
            try {
                if(this.model.domicilio > 65000){
                    this.notificacion('Advertencia','El costo maximo para domicilio es de $65.000','warning')
                    return
                }
                this.loading = true
                const valid = await this.$refs.form1.validate()
                if(valid){
                    const {data} = await Pedidos.crear_pedido(this.model)
                    this.notificacion('Éxito','Pedido almacenado correctamente','success')
                    this.$router.push({params:{id_pedido:data.id}})
                    this.$refs.crearPedido.toggle()
                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        set_tienda(id){
            this.tienda = this.opciones.find(o=>o.value === id)
            this.money.precision = this.tienda.decimales

            const fecha = moment().format('YYYY-MM-DD')
            this.set_franja(fecha)
            this.setDirecciones()
        },
        async set_franja(fecha){
            try {
                let id_cedis = this.tienda.id_cedis
                const vistaAdmin = true
                const {data} = await Pedidos.setFranjasHorarios(id_cedis,fecha,vistaAdmin)
                this.horaEntrega = data?.data
                this.model.entrega_horario = ""
                this.horario = ""
                // if(data.horarios.length === 0){
                //     this.errorFecha = true
                // }else{
                //     this.errorFecha = false
                // }
                // this.fechaValida = _.cloneDeep(data.fecha_valida)
                // this.horarios = data.horarios;
                // this.fechaMinima = moment(data.fecha_valida).subtract(1, "days");

            } catch (e){
                this.error_catch(e)
            }
        },
        async setDirecciones(){
            try {
                this.direccion_seleccionada = null,
                this.model.direccion_tendero = '';
                this.model.longitud_tendero = 0;
                this.model.latitud_tendero = 0;
                this.model.distancia_tendero = 0;
                const {data} = await Pedidos.selectDirecciones(this.tienda.id)
                this.direcciones = data.direcciones
                if(data.actual){
                    this.set_direccion_lechero(data.actual)
                    this.direccion_seleccionada = data.actual;
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        set_direccion_lechero(id){
            let direccion = this.direcciones.find(o => o.id === id );
            this.model.direccion_tendero = direccion.direccion;
            this.model.longitud_tendero = direccion.longitud;
            this.model.latitud_tendero = direccion.latitud;
            this.model.distancia_tendero = direccion.distancia;
        },
    }
}
</script>

<style lang="css" scoped>
.card-prod{
    width: 330px;
    display: flex;
}
.text-aqua{
    color: #03D6BC;
}
</style>
